<template>
  <div></div>
</template>

<script>
import User from '@/classes/User'
import Organisations from '@/classes/Organisations'
import onUserLoaded from '@/utils/onUserLoaded'

export default {
  data() {
    return {
      Organisations,
    }
  },
  mounted() {
    onUserLoaded(this.initialise)
  },
  methods: {
    async initialise() {
      if (User.isSuperAdmin) {
        this.$router.push({ name: 'admin-list-workspaces' })
      } else if (User.isAdmin) {
        this.$router.push({ name: 'admin-list-users' })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
    },
  },
}
</script>
